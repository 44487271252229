import React, { Fragment, useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Table as MTable,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
} from "@material-ui/core";

import { InfiniteScroll, Loading, createSnackbar } from "../_common";

const Table = forwardRef(
  (
    {
      onFetch,
      headers,
      header,
      initialOrder,
      containerId,
      children,
      allSelected,
      onSelectAll,
      mostraTotal = false,
      valorTotal,
      fetchRef,
      isPaginated = true,
      emptyContent,
      setQtdFiltros,
      ...props
    },
    ref
  ) => {
    const intl = useIntl();
    const [content, setContent] = useState([]);
    const [orderBy, setOrderBy] = useState(initialOrder ? initialOrder.header : headers[0].path);
    const [ascending, setAscending] = useState(initialOrder ? initialOrder.asc : true);
    const page = useRef(0);
    const hasMore = useRef(false);
    const [loading, setLoading] = useState(true);
    const firstRender = useRef(true);

    const [singleRequest, setSingleRequest] = useState(!isPaginated);

    if (ref) {
      useImperativeHandle(
        ref,
        () => ({
          itens: content,
          setItens: setContent,
          reloadData: () => fetch(true),
        }),
        [content, ref.current]
      );
    }

    useEffect(
      () => {
        if (firstRender.current) firstRender.current = false;
        else fetch(true);

        if (singleRequest) {
          fetch(true);
          setSingleRequest(false);
        }
      },
      [onFetch, orderBy, ascending]
    );

    useEffect(
      () => {
        if (fetchRef) fetchRef.current = { sort, fetch };
      },
      [fetchRef, sort, fetch]
    );

    function sort(key) {
      if (!loading) {
        setOrderBy(key);
        setAscending(orderBy === key ? !ascending : true);
      }
    }

    async function fetch(reset) {
      setLoading(true);
      if (reset || !isPaginated) {
        page.current = 0;
        setContent([]);
      }

      try {
        const { data } = await onFetch({ orderBy, ascending }, page.current++);
        if (setQtdFiltros !== undefined) setQtdFiltros(data.length);
        if (isPaginated) setContent(c => c.concat(data));
        else setContent(data);

        hasMore.current = !!data.length;
      } catch (err) {
        createSnackbar(err.message);
        setContent([]);
        hasMore.current = false;
      } finally {
        setLoading(false);
      }
    }

    return (
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetch()}
        hasMore={!loading && isPaginated && hasMore.current}
        loader={null}
        useWindow={false}
        queryselector={`#${containerId}`}
        style={{ width: "100%" }}
      >
        <MTable {...props}>
          {header ? (
            header({ ascending, setAscending, orderBy, setOrderBy, sort })
          ) : (
            <TableHead>
              {mostraTotal && (
                <TableRow>
                  <TableCell align="left" size="medium" colSpan={2} style={{ fontSize: "smaller", paddingTop: "1%" }}>
                    {intl.formatMessage({ defaultMessage: "Total de leads/oportunidades:" })} {valorTotal}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                {onSelectAll && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      disabled={typeof onSelectAll !== "function"}
                      checked={!!allSelected}
                      onChange={(e, selected) => onSelectAll(selected)}
                    />
                  </TableCell>
                )}
                {isPaginated &&
                  headers.map(
                    ({ text, path, style }) =>
                      path ? (
                        <TableCell key={path} onClick={() => !loading && sort(path)} style={style}>
                          <TableSortLabel active={path === orderBy} direction={ascending ? "desc" : "asc"}>
                            {typeof text === "object" ? intl.formatMessage(text) : text}
                          </TableSortLabel>
                        </TableCell>
                      ) : (
                        <TableCell key={path} style={style}>
                          {typeof text === "object" ? intl.formatMessage(text) : text}
                        </TableCell>
                      )
                  )}

                {!isPaginated &&
                  headers.map(({ text, path, style }) => (
                    <TableCell key={path} style={style}>
                      {typeof text === "object" ? intl.formatMessage(text) : text}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody className="table">
            {content.map((item, index) => (
              <Fragment key={item.id || index}>{children(item, index, { fetch })}</Fragment>
            ))}
            {loading && (
              <TableRow>
                <TableCell align="center" colSpan={headers.length + 1}>
                  <Loading isLoading />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              !content.length && (
                <TableRow>
                  <TableCell align="center" colSpan={headers.length + 1} style={{ height: 300 }}>
                    {emptyContent}
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </MTable>
      </InfiniteScroll>
    );
  }
);

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  header: PropTypes.func,
  initialOrder: PropTypes.shape({
    asc: PropTypes.bool,
    header: PropTypes.string,
  }),
  onFetch: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
  fetchRef: PropTypes.object,
  isPaginated: PropTypes.bool,
  emptyContent: PropTypes.node,
  mostraTotal: PropTypes.bool,
  valorTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setQtdFiltros: PropTypes.func,
};

export default Table;
