import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Select } from "@material-ui/core";
import { get } from "dot-prop-immutable";

import { useLista } from "../hooks";

function SelectPreVendedor({ value, onChange, field, form, ...props }) {
  const [preVendedores, loading] = useLista("/api/pipeline/usuario1/listar?tipo=PreVendedor");

  return (
    <Select
      disabled={loading}
      value={field ? get(form.values, field.name) : value}
      onChange={v => (field ? form.setFieldValue(field.name, v.target.value) : onChange(v.target.value))}
      error={field ? !!get(form.errors, field.name) : false}
      {...props}
    >
      <MenuItem value="">Todos</MenuItem>
      {preVendedores.map(({ id, descricao }) => (
        <MenuItem key={id} value={id}>
          {descricao}
        </MenuItem>
      ))}
    </Select>
  );
}

SelectPreVendedor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  field: PropTypes.object,
  form: PropTypes.object,
  name: PropTypes.string,
};

export default SelectPreVendedor;
