import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { ListItemText } from "@material-ui/core";
import { AsyncWithSearch } from "../AsyncSelect";
import { LABELS_TIPO_GATE, TIPO_GATE } from "../../_common/constantes";

SelectConversoes.propTypes = {
  value: PropTypes.shape({
    tipoGate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    origem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    destino: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    questionario: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func,
};

const URL = "/api/pipeline/etapa/ListarPassagens?SepararQuestionarios=true&todosFunis=true&funisAtivos=true";

const getId = etapa =>
  `${etapa.origem.tipoGate}-${etapa.origem.id}-${etapa.destino.id}-${etapa.origem.questionarioId || ""}`;

function SelectConversoes(props) {
  const intl = useIntl();
  const { value } = props;
  const conversao = `${value.tipoGate}-${value.origem}-${value.destino}-${value.questionario || ""}`;

  const handleChange = React.useCallback((e, v) => {
    const { value: etapa } = e.target;
    if (etapa) {
      const [tipoGate, origem, destino, questionario] = etapa.split("-").map(Number);
      e.target.value = { tipoGate, origem, destino, questionario };
    } else {
      e.target.value = {};
    }
    props.onChange(e, v);
  }, []);

  return (
    <AsyncWithSearch
      label={intl.formatMessage({ defaultMessage: "Etapa" })}
      url={URL}
      transformPayload={conversoes =>
        conversoes.map(c => ({ ...c, search: getContentLabelConversao(c, intl).join(" ") }))
      }
      getId={getId}
      getLabel={etapa => getLabelConversao(etapa, intl)}
      getInputLabel={etapa => getInputLabel(etapa, intl)}
      InputLabelProps={{ shrink: !!value?.questionario }}
      {...props}
      value={conversao}
      onChange={handleChange}
    />
  );
}

export function getLabelConversao(etapa, intl) {
  const [primario, secundario] = getContentLabelConversao(etapa, intl);
  return <ListItemText style={{ paddingLeft: 0 }} inset primary={primario} secondary={secundario} />;
}

function getContentLabelConversao(etapa, intl) {
  let primario = formatarTexto(etapa, intl);
  let secundario = "";
  if (etapa.origem.tipoGate) {
    const aux1 =
      etapa.origem.tipoGate !== TIPO_GATE.MANUAL
        ? intl.formatMessage(
            {
              defaultMessage: "Conversão por {tipoGate}",
            },
            { tipoGate: intl.formatMessage(LABELS_TIPO_GATE[etapa.origem.tipoGate]) }
          )
        : intl.formatMessage(
            {
              defaultMessage: "Conversão {tipoGate}",
            },
            { tipoGate: intl.formatMessage(LABELS_TIPO_GATE[etapa.origem.tipoGate]) }
          );

    const aux2 = etapa.origem.questionario
      ? intl.formatMessage(
          {
            defaultMessage: "em {questionario}",
          },
          { questionario: etapa.origem.questionario }
        )
      : "";

    secundario = `${aux1} ${aux2}`;
  }

  const funil = formatarFunil(etapa, intl);
  primario = `${primario} ${funil}`;

  return [primario, secundario];
}

function getInputLabel(etapa, intl) {
  const primario = formatarTexto(etapa, intl);
  const secundario = etapa.origem.tipoGate ? etapa.origem.questionario : "";
  const funil = formatarFunil(etapa, intl);

  return `${primario}${etapa.origem.questionarioId ? ` (${secundario}) ${funil}` : ""}`;
}

function formatarTexto(etapa, intl) {
  const prefix = intl.formatMessage({ defaultMessage: "De {descricao}" }, { descricao: etapa.origem.descricao });
  const sufix =
    etapa.destino && etapa.destino.descricao
      ? intl.formatMessage({ defaultMessage: "para {descricao}" }, { descricao: etapa.destino.descricao })
      : undefined;

  const primario = `${prefix} ${sufix}`;

  return primario;
}

function formatarFunil(etapa, intl) {
  return etapa.mostrarFunil
    ? `[${intl.formatMessage({
        defaultMessage: "Funil",
      })} ${etapa.funil}]`
    : "";
}

export default SelectConversoes;
