import React from "react";
import PropTypes from "prop-types";
import NumberMask from "react-number-format";

PositiveIntegerInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

function PositiveIntegerInput({ inputRef, ...rest }) {
  return (
    <NumberMask
      getInputRef={inputRef}
      allowNegative={false}
      fixedDecimalScale
      decimalScale={0}
      allowEmptyFormatting
      {...rest}
    />
  );
}

export default React.memo(PositiveIntegerInput);
