import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles, Link } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import useSWR from "swr";

import { Telefones } from "../../../pages/lead/components";
import CampoSemelhantes from "../CampoSemelhantes/CampoSemelhantes";
import useCamposSemelhantesOrganizacao from "../CampoSemelhantes/useCamposSemelhantesOrganizacao";
import CampoCpfCnpj from "../../CampoCpfCnpj";
import { CAMPOS_SEMELHANTES_ORGANIZACAO } from "../constants";
import OrganizacaoSemelhantes from "../CampoSemelhantes/OrganizacaoSemelhantes";
import { ActionButton } from "../../ActionButton";

const validateRequired = (value = "", intl) => {
  if (!value.toString().trim()) {
    return intl.formatMessage({ defaultMessage: "Campo obrigatório*" });
  }
  return "";
};

OrganizacaoEditFormBase.propTypes = {
  orgId: PropTypes.number,
  onClickDesvincular: PropTypes.func,
  /**
   * Caso esta função seja passada. O botão de "vincular" será mostrado
   * na modal de semelhantes, e esta ação será atribuida ao botão.
   */
  onClickVincularSemelhante: PropTypes.func,
  /**
   * Caso seja informado um id, apresenta a opção preenchimento automático
   */
  autoFillLeadId: PropTypes.number,
};

OrganizacaoEditFormBase.defaultProps = {
  orgId: null,
  autoFillLeadId: null,
};

/**
 * OrganizacaoEditFormBase é reutilizado na edição e cadastro da Organização.
 * Seus dados devem ser comuns aos dois casos de uso.
 * Para casos específicos que diferem no Cadastro e Edição,
 * utilizar composição dos forms.
 */
function OrganizacaoEditFormBase({ orgId, onClickDesvincular, onClickVincularSemelhante, autoFillLeadId, classes }) {
  const intl = useIntl();
  const { values, handleBlur, setValues } = useFormikContext();
  const showDesvincularButton = onClickDesvincular && orgId;
  const { data: lead } = useSWR(() => (autoFillLeadId ? `/api/pipeline/lead/empresa?id=${autoFillLeadId}` : null));

  const {
    setSearch: setSearchCpfCnpj,
    semelhantes: semelhantesCpfCnpj,
    isLoading: isLoadingSemelhantesCpfCnpj,
  } = useCamposSemelhantesOrganizacao({
    orgId,
    campo: CAMPOS_SEMELHANTES_ORGANIZACAO.CPF_CNPJ,
    valor: values?.cpfCnpj,
  });

  function autoFill() {
    if (lead) {
      setValues({ ...values, ...lead, endereco: { ...values.endereco, ...lead.endereco } }, true);
    }
  }

  return (
    <Grid container spacing={16}>
      {autoFillLeadId && (
        <Grid item xs={12}>
          <Link onClick={autoFill} component="button" underline="none">
            {intl.formatMessage({ defaultMessage: "Preencher com dados do Lead/Oportunidade" })}
          </Link>
        </Grid>
      )}
      <Grid item xs={showDesvincularButton ? 6 : 12}>
        <CampoSemelhantes
          name="nome"
          orgId={orgId}
          label={intl.formatMessage({ defaultMessage: "Nome" })}
          inputProps={{ maxLength: 500 }}
          fullWidth
          validate={val => validateRequired(val, intl)}
          required
          onClickVincularSemelhante={onClickVincularSemelhante}
          campo={CAMPOS_SEMELHANTES_ORGANIZACAO.NOME}
        />
      </Grid>
      {showDesvincularButton && (
        <Grid item xs={6} className={classes.gridButtonDesvincular}>
          <ActionButton
            id="btn-buscar-contatos"
            type="text"
            color="primary"
            label={intl.formatMessage({ defaultMessage: "Desvincular organização" })}
            onClick={onClickDesvincular}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CampoCpfCnpj
          name="cpfCnpj"
          config={{ habilitar: true }}
          fullWidth
          isLoading={isLoadingSemelhantesCpfCnpj}
          SemelhantesComponent={
            <OrganizacaoSemelhantes
              quantidade={semelhantesCpfCnpj?.quantidade}
              orgs={semelhantesCpfCnpj?.orgs}
              onClickVincularSemelhante={onClickVincularSemelhante}
            />
          }
          showSemelhantes={semelhantesCpfCnpj?.quantidade > 0}
          onBlur={event => {
            setSearchCpfCnpj(values?.cpfCnpj.replace(/\D/g, "").trim());
            handleBlur(event);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label={intl.formatMessage({ defaultMessage: "Site" })}
          component={TextField}
          name="site"
          inputProps={{ maxLength: 500 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Telefones name="telefones" deveBuscarSemelhantes={false} />
      </Grid>
    </Grid>
  );
}

export default withStyles({
  gridButtonDesvincular: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
})(OrganizacaoEditFormBase);
