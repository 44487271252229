import React from "react";
import { Form } from "formik";
import { Grid } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import { useIntl } from "react-intl";

import OrganizacaoEditFormBase from "./Base";
import { LeadEnderecoForm } from "../../../pages/lead/components";
import { Flex, Tipografia } from "../../../_common";

function OrganizacaoEditForm({ ...props }) {
  const intl = useIntl();
  return (
    <Form noValidate>
      <OrganizacaoEditFormBase {...props} />
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Flex style={{ marginTop: 16, marginBottom: 8 }}>
            <PlaceIcon style={{ marginRight: 8, marginLeft: -6 }} />
            <Tipografia cor="darkSecondaryText" tipo="tituloCard">
              {intl.formatMessage({ defaultMessage: "Endereço" })}
            </Tipografia>
          </Flex>
        </Grid>
        <Grid item xs={12}>
          <LeadEnderecoForm />
        </Grid>
      </Grid>
    </Form>
  );
}

export default OrganizacaoEditForm;
