import React, { Fragment, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { PropTypes } from "prop-types";
import { MenuItem, MenuList, withStyles, Typography, Popover, Button } from "@material-ui/core";
import moment from "moment";
import useSWR from "swr";
import DateRange from "@material-ui/icons/DateRange";
import dateUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, Calendar, BasePicker } from "material-ui-pickers";
import { OPCOES_PERIODO } from "../_common/constantes";
import { Flex } from "../_common";

const getDates = (periodo, format) => {
  const [start, end] = {
    hoje: () => [
      moment().startOf("day"),
      moment()
        .add(+1, "day")
        .endOf("day"),
    ],
    ontem: () => [
      moment()
        .add(-1, "day")
        .startOf("day"),
      moment()
        .add(-1, "day")
        .endOf("day"),
    ],
    semana: () => [moment().startOf("week"), moment().endOf("week")],
    mes: () => [moment().startOf("month"), moment().endOf("month")],
  }[periodo]();
  if (format) return [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
  return [start, end];
};

function SelectPeriodo({
  defaultOption = "mes",
  onChange,
  format = "YYYY-MM-DD",
  options = OPCOES_PERIODO,
  hideCustom = false,
  classes,
}) {
  const intl = useIntl();
  const [value, setValue] = useState(defaultOption);
  const [startDate, handleChangeStart] = useState(moment().startOf("month"));
  const [endDate, handleChangeEnd] = useState(moment().endOf("month"));
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const reposition = useRef(() => null);
  const { data: mesComercial } = useSWR("/api/pipeline/MesComercial/Vigente");

  function change(periodo) {
    setValue(periodo);
    onChange(periodo === "mesComercial" ? [mesComercial.item1, mesComercial.item2] : getDates(periodo, format));
    if (periodo !== "personalizado") setOpen(false);
  }

  function apply() {
    if (format) onChange([startDate.startOf("day").format(format), endDate.endOf("day").format(format)]);
    else onChange([startDate.startOf("day"), endDate.endOf("day")]);
    setOpen(false);
  }

  return (
    <MuiPickersUtilsProvider utils={dateUtils}>
      <Fragment>
        <Button onClick={() => setOpen(true)} buttonRef={ref} className={classes.button}>
          <DateRange className={classes.icon} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            {value !== "personalizado" && (
              <Typography className={classes.text}>{intl.formatMessage(options[value]?.label)}</Typography>
            )}
            {value === "personalizado" && (
              <Typography className={classes.text}>{`${startDate.format("DD/MM/YYYY")} - ${endDate.format(
                "DD/MM/YYYY"
              )}`}</Typography>
            )}
          </div>
        </Button>

        <Popover
          open={open}
          anchorEl={ref.current}
          onClose={() => setOpen(false)}
          className={classes.popover}
          action={({ updatePosition }) => {
            reposition.current = updatePosition;
          }}
        >
          <Flex>
            <div className={classes.menuContainer}>
              <MenuList disableListWrap>
                {Object.values(options).map(({ id, label }) => (
                  <MenuItem selected={value === id} key={id} onClick={() => change(id)}>
                    {intl.formatMessage(label)}
                  </MenuItem>
                ))}
                {!hideCustom && (
                  <MenuItem
                    onClick={() => {
                      setValue("personalizado");
                      reposition.current();
                    }}
                  >
                    {intl.formatMessage({ defaultMessage: "Personalizado" })}
                  </MenuItem>
                )}
              </MenuList>

              {value === "personalizado" && (
                <Button style={{ width: "100%" }} color="primary" onClick={apply}>
                  {intl.formatMessage({ defaultMessage: "Aplicar" })}
                </Button>
              )}
            </div>

            {value === "personalizado" && (
              <BasePicker onChange={() => null}>
                {() => (
                  <Fragment>
                    <div className={classes.calendar}>
                      <Calendar
                        date={startDate}
                        onChange={t => {
                          if (t.isAfter(endDate)) handleChangeEnd(t);
                          handleChangeStart(t);
                        }}
                      />
                    </div>
                    <div className={classes.calendar}>
                      <Calendar minDate={startDate} date={endDate} onChange={date => handleChangeEnd(date)} />
                    </div>
                  </Fragment>
                )}
              </BasePicker>
            )}
          </Flex>
        </Popover>
      </Fragment>
    </MuiPickersUtilsProvider>
  );
}

SelectPeriodo.propTypes = {
  defaultOption: PropTypes.string,
  onChange: PropTypes.func,
  format: PropTypes.string,
  options: PropTypes.object,
  classes: PropTypes.object,
  hideCustom: PropTypes.bool,
};

export default withStyles(theme => ({
  icon: {
    color: theme.palette.icons.darkInactiveIcon,
  },
  text: { marginLeft: "4px", color: theme.palette.primary1Color, fontWeight: 500 },
  menuContainer: {
    borderRight: "1px solid lightgray",
    zIndex: 1,
    background: theme.tabs.backgroundColor,
  },
  calendar: {
    borderRight: "1px solid lightgray",
    padding: 12,
  },
  button: { whiteSpace: "nowrap", marginRight: "1em" },
}))(SelectPeriodo);
