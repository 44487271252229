import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, MenuItem, IconButton, Radio, withStyles, FormControl } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import AddCircle from "@material-ui/icons/AddCircle";
import { FieldArray, Field } from "formik";
import { get, set } from "dot-prop-immutable";
import AutoSizer from "react-virtualized-auto-sizer";

import { Flex, Tipografia, SelectScrollableFormik } from "../_common";

function SelectUnique({ name, options, label, emptyButtonLabel = "Adicionar", hasPrincipal, classes }) {
  const map = options.reduce((ac, vl, ind) => ({ ...ac, [vl.id]: ind }), {});
  const [opts, setOpts] = useState(options);
  useEffect(
    () => {
      setOpts(options);
    },
    [options]
  );

  const onRadioChange = (values, ind) =>
    set(values, name, v => v.map((value, i) => ({ ...value, principal: hasPrincipal && ind === i })));

  return (
    <FieldArray
      name={name}
      render={({ remove, push, replace, form: { values, setValues, isSubmitting } }) => {
        const entries = get(values, name);
        return (
          <Fragment>
            {entries.length ? (
              entries.map(({ principal, id: entryId }, index) => (
                <Flex justifyContent="space-between" key={index} className={classes.row}>
                  <Flex style={{ flexGrow: 1 }} alignItems="center">
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <FormControl>
                          <Field
                            name={`${name}.${index}.id`}
                            component={SelectScrollableFormik}
                            style={{ width }}
                            className={classes.field}
                            label={label ? `${label} ${index + 1}` : ""}
                            onClick={({ target: { value: id } }) => {
                              if (id) {
                                replace(index, {
                                  ...options[map[id]],
                                  id,
                                  principal,
                                });
                              }
                            }}
                          >
                            {opts.map(({ id, descricao }) => (
                              <MenuItem
                                value={id}
                                key={id}
                                disabled={id !== entryId && !!get(values, name).find(({ id: v }) => id === v)}
                              >
                                {descricao}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      )}
                    </AutoSizer>
                  </Flex>

                  <Flex className={classes.botoes}>
                    <IconButton
                      size="small"
                      onClick={() => remove(index)}
                      className={classes.showOnHover}
                      disabled={isSubmitting}
                    >
                      <Clear />
                    </IconButton>

                    <IconButton
                      size="small"
                      className={index + 1 < entries.length ? classes.hidden : ""}
                      onClick={() => {
                        const option = options.find(({ id }) => entries.every(({ id: v }) => v !== id));
                        push({
                          principal: false,
                          ...option,
                        });
                      }}
                      disabled={entries.length >= options.length || isSubmitting}
                    >
                      <AddCircle />
                    </IconButton>

                    {hasPrincipal && (
                      <Radio
                        checked={principal}
                        color="primary"
                        nome="dor-principal"
                        onChange={() => setValues(onRadioChange(values, index))}
                        disabled={isSubmitting}
                      />
                    )}
                  </Flex>
                </Flex>
              ))
            ) : (
              <Flex flexDirection="column" alignItems="flex-start" style={{ paddingTop: 12 }}>
                <Tipografia cor="darkSecondaryText">Nenhum {label} registrado.</Tipografia>
                <Button
                  onClick={() => {
                    const option = options.find(({ id }) => entries.every(({ id: v }) => v !== id));
                    push({
                      principal: hasPrincipal,
                      ...option,
                    });
                  }}
                  className={classes.addButton}
                  disabled={isSubmitting}
                >
                  {emptyButtonLabel}
                </Button>
              </Flex>
            )}
          </Fragment>
        );
      }}
    />
  );
}

SelectUnique.propTypes = {
  name: PropTypes.string,
  hasPrincipal: PropTypes.bool,
  label: PropTypes.string,
  emptyButtonLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      descricao: PropTypes.string.isRequired,
      principal: PropTypes.bool,
    })
  ),
  classes: PropTypes.object,
};

export default withStyles({
  field: { flexGrow: 1 },
  addButton: { margin: "12px 0 24px" },
  botoes: {
    alignSelf: "flex-end",
    width: "144px",
    justifyContent: "flex-end",
  },
  hidden: { visibility: "hidden" },
  showOnHover: { visibility: "hidden" },
  row: { "&:hover $showOnHover": { visibility: "visible" } },
})(SelectUnique);
