import React from "react";
import PropTypes from "prop-types";
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

function SelectMultiple({ values, setValues, label, options, loading }) {
  function handleChange(event, { key }) {
    if (key === "all_values") setValues(values.length === options.length ? [] : options.map(({ id }) => id));
    else setValues(event.target.value);
  }

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={values}
        onChange={handleChange}
        input={<Input id="select-multiple" />}
        IconComponent={loading ? CircularProgress : ArrowDropDown}
        disabled={loading}
        style={{ width: 200 }}
        renderValue={selected => selected.map(id => options.find(option => id === option.id).descricao).join(", ")}
        name="select"
      >
        <MenuItem key="all_values">
          <Checkbox
            color="primary"
            checked={values.length === options.length}
            indeterminate={!!values.length && values.length < options.length}
          />
          <ListItemText primary="Todos" />
        </MenuItem>
        {options.map(({ id, descricao }) => (
          <MenuItem key={id} value={id}>
            <Checkbox color="primary" checked={values.indexOf(id) > -1} />
            <ListItemText primary={descricao} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectMultiple.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number),
  setValues: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      descricao: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};

export default SelectMultiple;
