import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import useLista from "../hooks/useLista";
import { MultiSelect } from "../_common";

const OPCAO_DESCARTADOS = {
  id: -1,
  nome: "Descartados",
  descricao: "Descartados",
  checked: true,
};

const styles = {
  multiSelectWrapper: {
    width: 180,

    "@media (max-width: 1400px)": {
      width: 110,
    },
  },
};

function SelectEtapas({ onChange, incluirDescartados = false, label, isFiltroAtividadesNovo, classes }) {
  const [lista] = useLista("/api/pipeline/Funil/ListarComEtapas");

  const etapas = [];
  lista.forEach(f => {
    f.etapas.map(e => etapas.push({ id: e.id, nome: `${f.nome} - ${e.nome}` }));
  });

  const descartados = [OPCAO_DESCARTADOS];
  const opcoes = React.useMemo(() => etapas.map(op => ({ ...op, descricao: op.nome, checked: true })), [etapas]);

  return (
    !!opcoes.length && (
      <div className={isFiltroAtividadesNovo ? classes.multiSelectWrapper : ""}>
        <MultiSelect
          floatingLabelText={label}
          initialItems={incluirDescartados ? descartados.concat(opcoes) : opcoes}
          onChange={ids =>
            onChange(ids.length === (incluirDescartados ? descartados.concat(opcoes).length : opcoes.length) ? [] : ids)
          }
          width={isFiltroAtividadesNovo ? "100%" : 180}
        />
      </div>
    )
  );
}

SelectEtapas.propTypes = {
  onChange: PropTypes.func,
  incluirDescartados: PropTypes.bool,
  label: PropTypes.string,
  isFiltroAtividadesNovo: PropTypes.bool,
};

export default withStyles(styles)(SelectEtapas);
