import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { CircularProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "dot-prop-immutable";

import { CAMPOS_SEMELHANTES_ORGANIZACAO } from "../constants";
import OrganizacaoSemelhantes from "./OrganizacaoSemelhantes";
import useCamposSemelhantesOrganizacao from "./useCamposSemelhantesOrganizacao";

OrganizacaoCampoSemelhantes.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  campo: PropTypes.oneOf(Object.values(CAMPOS_SEMELHANTES_ORGANIZACAO)).isRequired,
  /**
   * Caso esta função seja passada. O botão de "vincular" será mostrado
   * na modal de semelhantes, e esta ação será atribuida ao botão.
   *
   * `onClickVincularSemelhante(orgId, onClose)`
   */
  onClickVincularSemelhante: PropTypes.func,
};

function OrganizacaoCampoSemelhantes({
  name,
  campo,
  orgId = null,
  helperText = "",
  onClickVincularSemelhante = null,
  ...props
}) {
  const { values, ...bag } = useFormikContext();
  const error = get(bag.errors, name);
  const touched = get(bag.touched, name);
  const hasError = !!error && touched;
  const value = get(values, name, "");

  const { setSearch, semelhantes, isLoading } = useCamposSemelhantesOrganizacao(
    {
      orgId,
      campo,
      valor: value,
    },
    500
  );

  React.useEffect(
    () => {
      setSearch(value);
    },
    [value]
  );

  return (
    <React.Fragment>
      <Field
        name={name}
        component={TextField}
        InputProps={{
          endAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : undefined,
          error: !!semelhantes?.quantidade || hasError,
        }}
        InputLabelProps={{ error: !!semelhantes?.quantidade || hasError }}
        FormHelperTextProps={{ error: !!semelhantes?.quantidade || hasError }}
        {...props}
        helperText={
          semelhantes?.quantidade > 0 ? (
            <OrganizacaoSemelhantes
              orgs={semelhantes?.orgs}
              quantidade={semelhantes?.quantidade}
              onClickVincularSemelhante={onClickVincularSemelhante}
            />
          ) : (
            `${hasError ? bag.errors[name] : helperText}`
          )
        }
      />
    </React.Fragment>
  );
}

export default OrganizacaoCampoSemelhantes;
