import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import axios from "axios";
import SelectAutoComplete from "./SelectAutoComplete";
import { maybeCallback } from "../_common/utils/fp";
import { createSnackbar } from "../_common";

function SelectEstado({
  value,
  onChange,
  field,
  form,
  pais,
  disabled,
  onLoadData,
  validate,
  showLoader = false,
  ...props
}) {
  const firstRender = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const change = field ? v => form.setFieldValue(field.name, v) : v => onChange(v);
  const [estados, setEstados] = useState([]);
  const intl = useIntl();

  async function fetchEstados() {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/api/pipeline/estado/listar?paisid=${pais}`);
      setEstados(data);
      maybeCallback(onLoadData)(data);
    } catch (err) {
      createSnackbar("Erro ao buscar estados.");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(
    () => {
      if (!firstRender.current) firstRender.current = true;
      else change({});
      if (!pais) setEstados([]);
      else fetchEstados();
    },
    [pais]
  );

  useEffect(
    () => {
      maybeCallback(onLoadData)(estados);
    },
    [estados]
  );

  return (
    <SelectAutoComplete
      label={intl.formatMessage({ defaultMessage: "Estado" })}
      placeholder={intl.formatMessage({ defaultMessage: "Digite para filtrar" })}
      options={estados}
      value={field ? field.value : value}
      onChange={nome =>
        change(nome ? { id: estados.find(({ descricao }) => descricao === nome).id, descricao: nome } : {})
      }
      disabled={!estados.length || disabled}
      isLoading={showLoader || isLoading}
      {...props}
    />
  );
}

SelectEstado.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    descricao: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  pais: PropTypes.number,
  field: PropTypes.object,
  form: PropTypes.object,
  onLoadData: PropTypes.func,
  showLoader: PropTypes.bool,
  validate: PropTypes.func,
};

export default withStyles({})(SelectEstado);
