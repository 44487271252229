import { GET_DEFAULT_TELEFONE } from "../../../pages/lead/components/Telefones/Telefones";
import { PAIS_BRASIL } from "../../../_common/constantes";

export const ORG_BASE_FORM_INITIAL_VALUES = () => ({
  nome: "",
  site: "",
  telefones: [GET_DEFAULT_TELEFONE()],
  cpfCnpj: "",
  endereco: {
    pais: { ...PAIS_BRASIL },
    estado: { id: null, descricao: "" },
    cidade: { id: null, descricao: "" },
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
  },
});

export const ORG_OBSERVACAO_FORM_INITIAL_VALUES = () => ({
  observacao: "",
});
