import React from "react";
import { useField } from "formik";

import SelectGroup from "./SelectGroup";

function FormikSelectGroup(props) {
  const [field, meta] = useField({ name: "grupoId", ...props });

  return <SelectGroup {...field} {...meta} {...props} />;
}

export default FormikSelectGroup;
