import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import axios from "axios";
import useSWR from "swr";
import { usePermission, useTipoPlanoSpotter } from "../../hooks";
import AsyncSelect from "../AsyncSelect";
import permissions from "../../_common/permissions";

const fetcher = url => axios.get(url).then(res => res.data);

function SelectMotivoDescarte(props) {
  const URL = `/api/pipeline/MotivoDescarte/Listar?somenteAtivos=true&traduzirDescricao=true&Padroes=${props?.padroes}`;
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const temPermissao = usePermission(permissions.MOTIVO_DESCARTE) && isSpotterFull;
  const [valor, setValor] = useState(props.value || []);

  const { data: listaMotivos, mutate: recarregarListaMotivos } = useSWR(URL, fetcher);
  const habilitarCriarNovo = props?.habilitarCriarNovo === undefined ? true : props?.habilitarCriarNovo;
  const alterarValor = id => {
    if (id !== undefined) setValor(id);
  };

  return (
    <AsyncSelect
      getId={item => item.id}
      getLabel={item => item.descricao}
      getInputLabel={item => item.descricao}
      label={intl.formatMessage({ defaultMessage: "Motivo de descarte" })}
      id="motivo-descarte"
      options={listaMotivos}
      enableSearch
      criarNovo={temPermissao && habilitarCriarNovo}
      urlCriarNovo={`/spotter/configuracoes/padroes`}
      onChange={event => alterarValor(event.targer.value)}
      funcaoRecarregar={recarregarListaMotivos}
      value={valor}
      {...props}
    />
  );
}

SelectMotivoDescarte.propTypes = {
  value: PropTypes.array,
  padroes: PropTypes.bool,
  habilitarCriarNovo: PropTypes.bool,
};

export default SelectMotivoDescarte;
