import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, IconButton, CircularProgress } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import { Formik } from "formik";

import { Flex, Tipografia } from "../_common";

function Outline({ schema, initialValues, onSubmit, loading, title, children, classes, ...props }) {
  const [editting, setEditting] = useState(false);

  async function submit(values, form) {
    await onSubmit(values);
    form.setSubmitting(false);
    setEditting(false);
  }

  return (
    <Formik
      {...props}
      validationSchema={schema}
      enableReinitialize
      initialValues={initialValues}
      onReset={() => setEditting(false)}
      onSubmit={submit}
    >
      {formikBag => (
        <Flex className={classes.root} flexDirection="column">
          <Flex className={classes.title} justifyContent="space-between" alignItems="center">
            <Tipografia tipo="tituloCard">{title}</Tipografia>
            {!editting && (
              <IconButton disabled={loading} onClick={() => setEditting(true)}>
                {loading ? <CircularProgress size={24} /> : <Edit />}
              </IconButton>
            )}
          </Flex>
          {children({ editting }, formikBag)}
        </Flex>
      )}
    </Formik>
  );
}

Outline.propTypes = {
  schema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles({
  root: {
    border: "1px solid lightgrey",
    borderRadius: 10,
    margin: 10,
  },
  title: {
    padding: 20,
  },
})(Outline);
