import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import { useIntl } from "react-intl";
import { MenuItem, withStyles } from "@material-ui/core";
import { Flex } from "../_common";
import { LABELS_BASE_LEGAL, BASE_LEGAL } from "../_common/constantes";

function SelectBasesLegais({ classes, values, fieldNames }) {
  const intl = useIntl();

  return (
    <>
      <Flex flexDirection="column">
        <Field
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label={intl.formatMessage({ defaultMessage: "Base legal para coleta dos dados" })}
          select
          component={TextField}
          id="field-coletaDados"
          name={fieldNames?.coletaDados}
          className={values.coletaDados === BASE_LEGAL.NENHUMA ? classes.italic : classes.normal}
        >
          {BASE_LEGAL_OPTIONS.map(({ id, texto }) => (
            <MenuItem
              id={`menu-item-${id}-coletaDados`}
              key={id}
              value={id}
              className={id === BASE_LEGAL.NENHUMA ? classes.italic : classes.normal}
            >
              {intl.formatMessage(texto)}
            </MenuItem>
          ))}
        </Field>
      </Flex>
      <Flex flexDirection="column">
        <Field
          InputLabelProps={{
            shrink: true,
          }}
          label={intl.formatMessage({ defaultMessage: "Base legal para envio de comunicação" })}
          select
          component={TextField}
          fullWidth
          id="field-envioComunicacao"
          name={fieldNames?.envioComunicacao}
          className={values.envioComunicacao === BASE_LEGAL.NENHUMA ? classes.italic : classes.normal}
        >
          {BASE_LEGAL_OPTIONS.map(({ id, texto }) => (
            <MenuItem
              id={`menu-item-${id}-envioComunicacao`}
              key={id}
              value={id}
              className={id === BASE_LEGAL.NENHUMA ? classes.italic : classes.normal}
            >
              {intl.formatMessage(texto)}
            </MenuItem>
          ))}
        </Field>
      </Flex>
      <Flex flexDirection="column">
        <Field
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label={intl.formatMessage({ defaultMessage: "Base legal para coleta de dados de voz" })}
          select
          component={TextField}
          id="field-dadosDados"
          name={fieldNames?.dadosVoz}
          className={values.dadosVoz === BASE_LEGAL.NENHUMA ? classes.italic : classes.normal}
        >
          {BASE_LEGAL_OPTIONS.filter(base => base.id < BASE_LEGAL.OBRIGACAO_LEGAL_REGULATORIA).map(({ id, texto }) => (
            <MenuItem
              id={`menu-item-${id}-dadosVoz`}
              key={id}
              value={id}
              className={id === BASE_LEGAL.NENHUMA ? classes.italic : classes.normal}
            >
              {intl.formatMessage(texto)}
            </MenuItem>
          ))}
        </Field>
      </Flex>
    </>
  );
}

export const BASE_LEGAL_OPTIONS = [
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.NENHUMA],
    id: BASE_LEGAL.NENHUMA,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.CONSENTIMENTO],
    id: BASE_LEGAL.CONSENTIMENTO,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.LEGITIMO_INTERESSE],
    id: BASE_LEGAL.LEGITIMO_INTERESSE,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.OBRIGACAO_LEGAL_REGULATORIA],
    id: BASE_LEGAL.OBRIGACAO_LEGAL_REGULATORIA,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.EXECUCAO_POLITICAS_PUBLICAS],
    id: BASE_LEGAL.EXECUCAO_POLITICAS_PUBLICAS,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.EXECUCAO_CONTRATO],
    id: BASE_LEGAL.EXECUCAO_CONTRATO,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.PROCESSO_JUDICIAL_ADMINISTRATIVO_ARBITRAL],
    id: BASE_LEGAL.PROCESSO_JUDICIAL_ADMINISTRATIVO_ARBITRAL,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.PROTECAO_VIDA_INCOLMIDADE_FISICA],
    id: BASE_LEGAL.PROTECAO_VIDA_INCOLMIDADE_FISICA,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.TUTELA_SAUDE],
    id: BASE_LEGAL.TUTELA_SAUDE,
  },
  {
    texto: LABELS_BASE_LEGAL[BASE_LEGAL.PROTECAO_CREDITO],
    id: BASE_LEGAL.PROTECAO_CREDITO,
  },
];

SelectBasesLegais.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object.isRequired,
  fieldNames: PropTypes.object.isRequired,
};

export default withStyles(theme => ({
  italic: {
    fontStyle: "italic",
  },
  normal: {
    fontStyle: "normal",
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
  },
  field: {
    width: "100%",
  },

  content: {
    padding: "12px 24px",
  },
  envioComunicacao: {
    padding: "24px 0 12px",
  },
  coletaDados: {
    padding: "12px 0",
  },
  dadosVoz: {
    padding: "12px 0",
  },
  inputLabel: {
    fontSize: "12px",
    color: theme.palette.text.darkSecondaryText,
  },
  actions: {
    padding: 8,
  },
}))(SelectBasesLegais);
