import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const ApiContext = createContext({});

const API_PRIVADA = {
  URL_MOTIVOS: "/api/pipeline/MotivoReuniao/Listar",
  URL_QUESTIONARIOS: "/api/pipeline/Reuniao/BuscarQuestionarios",
  URL_NAO_OCORREU: "/api/pipeline/Reuniao/NaoOcorreu",
  URL_QUESTIONARIO: "/api/pipeline/Questionario/Buscar",
  URL_SALVAR_QUESTIONARIO: "/api/pipeline/reuniao/feedback",
};

ApiContextProvider.propTypes = { children: PropTypes.node };
export function ApiContextProvider({ children }) {
  return <ApiContext.Provider value={API_PRIVADA}>{children}</ApiContext.Provider>;
}

export function useApiContext() {
  return useContext(ApiContext);
}

export default ApiContext;
