import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@material-ui/core";
import useSWR from "swr";
import { Loading } from "../_common";

export default function SelectCiclo({ id, onChange, linkPublico = false }) {
  const URL = linkPublico ? `/api/pipeline/leadPublico/cicloAtual?id=${id}` : `/api/pipeline/lead/ciclo?id=${id}`;
  const { data: cicloAtual } = useSWR(URL);
  const [ciclos, setCiclos] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(
    () => {
      if (typeof cicloAtual !== "undefined") {
        setCiclos(Array(...Array(cicloAtual)).map((x, i) => i + 1));
        setSelected(cicloAtual);
        if (typeof cicloAtual === typeof 1) onChange(cicloAtual);
      }
    },
    [cicloAtual]
  );

  function changeCiclo(e) {
    setSelected(e.target.value);
    onChange(e.target.value);
  }

  if (typeof cicloAtual === "undefined") return <Loading isLoading />;

  return (
    <Select id="selectCicloLead" style={{ width: 40 }} value={selected} onChange={changeCiclo}>
      {ciclos.map(ciclo => (
        <MenuItem id={`menuItem-Ciclos-${ciclo}`} key={ciclo} value={ciclo}>
          {ciclo}
        </MenuItem>
      ))}
    </Select>
  );
}

SelectCiclo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  linkPublico: PropTypes.bool,
};
