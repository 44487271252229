import React from "react";
import CamposPersonalizadosField from "../../../pages/lead/components/camposPersonalizados/CamposPersonalizadosField";

function OrganizacaoEditFormCamposPersonalizados({ campos }) {
  if (!campos || !campos?.length) return null;

  return campos.map((campo, index) => (
    <span data-scroll-anchor={`camposPersonalizados.${index}`} key={campo.id}>
      <CamposPersonalizadosField
        name={`camposPersonalizados.${index}`}
        campo={campo}
        shouldValidateUnique={false}
        isOrganizacao
      />
    </span>
  ));
}

export default OrganizacaoEditFormCamposPersonalizados;
