import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

TooltipComponent.propTypes = { title: PropTypes.string };
export default function TooltipComponent({ title, ...tooltipProps }) {
  const { childrenClassName, ...minusChildrenClassname } = tooltipProps;

  return (
    <Tooltip disableFocusListener title={title}>
      <li {...minusChildrenClassname} />
    </Tooltip>
  );
}
