import React from "react";
import { useIntl } from "react-intl";
import { usePermission, useTipoPlanoSpotter } from "../../hooks";
import permissions from "../../_common/permissions";
import AsyncSelect from "../AsyncSelect";

function SelectMotivoReuniao(props) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const URL = "/api/pipeline/MotivoReuniao/Listar";
  const temPermissao = usePermission(permissions.MOTIVO_REUNIAO_NAO_OCORRIDA) && isSpotterFull;

  return (
    <AsyncSelect
      label={intl.formatMessage({ defaultMessage: "Selecione o motivo de cancelamento da reunião" })}
      getId={item => item.id}
      getLabel={item => item.descricao}
      getInputLabel={item => item.descricao}
      id="motivo-cancelamento-reuniao"
      url={URL}
      enableSearch
      criarNovo={temPermissao}
      urlCriarNovo={`/spotter/configuracoes/padroes`}
      recarregarLista
      {...props}
    />
  );
}

export default SelectMotivoReuniao;
