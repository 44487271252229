import styled from "styled-components";

const Tile = styled.div`
  object-fit: cover;
  grid-gap: 1em;
  grid-column: span ${props => props.width ?? 1};
  grid-row: span ${props => props.height ?? 1};
`;

export default Tile;
