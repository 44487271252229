import React from "react";
import PropTypes from "prop-types";
import { withStyles, CardHeader, Avatar, Typography } from "@material-ui/core";

import { Flex } from "../_common";

function StackedCardHeader({ title, subheader, icon: Icon, classes, ...props }) {
  return (
    <CardHeader
      disableTypography
      title={
        <Flex alignItems="center" flexDirection="column">
          <Avatar className={classes.avatar}>
            <Icon className={classes.icon} />
          </Avatar>
          <Typography variant="headline">{title}</Typography>
          <Typography>{subheader}</Typography>
        </Flex>
      }
      {...props}
    />
  );
}

StackedCardHeader.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  icon: PropTypes.node,
};

export default withStyles({
  avatar: { height: 92, width: 92, backgroundColor: "#E4E4E4" },
  icon: { height: 48, width: 48, color: "#A0A0A0" },
})(StackedCardHeader);
