import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Paper, IconButton } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Link } from "react-router-dom";

import { Breadcrumb, Flex, TooltipTruncateV0 } from "../_common";

function Subheader({ title, hideBackButton, breadcrumb, appendToTitle, funcaoOnClick, children, classes }) {
  const previousPage = React.useMemo(
    () =>
      breadcrumb
        ?.slice()
        .reverse()
        .find(({ url }) => !!url),
    [breadcrumb]
  );

  return (
    <Fragment>
      {title && (
        <Paper className={classes.header} elevation={0} square>
          <Flex alignItems="center">
            {!hideBackButton &&
              previousPage && (
                <Link to={previousPage.url}>
                  <IconButton className={classes.backButton} onClick={funcaoOnClick}>
                    <KeyboardArrowLeft className={classes.backButtonIcon} />
                  </IconButton>
                </Link>
              )}
            <TooltipTruncateV0 className={classes.title} tipo="titulo1">
              {title}
            </TooltipTruncateV0>
            {appendToTitle && <span className={classes.appendToTitle}>{appendToTitle}</span>}
          </Flex>
          {breadcrumb && <Breadcrumb className={classes.breadcrumb} valores={breadcrumb} />}
        </Paper>
      )}

      {children && (
        <Paper square className={classes.fixed}>
          {children}
        </Paper>
      )}
    </Fragment>
  );
}

Subheader.propTypes = {
  title: PropTypes.string,
  hideBackButton: PropTypes.bool,
  funcaoOnClick: PropTypes.func,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string,
      url: PropTypes.string,
      urlExterna: PropTypes.bool,
    })
  ),
  /** JSX adicional que será inserido após o título */
  appendToTitle: PropTypes.node,
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default withStyles({
  header: {
    padding: "16px 29px 16px 29px",
    backgroundColor: "#F5F5F5",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  fixed: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    zIndex: 3,
    top: 6,
    height: 56,
    boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    paddingRight: 34,
    marginBottom: 10,
  },
  backButton: {
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, .12) !important",
    height: "28px !important",
    width: "28px !important",
    padding: "0 !important",
  },
  backButtonIcon: {
    color: "rgba(0, 0, 0, .38)",
    marginRight: 2,
    height: "28px !important",
    width: "28px !important",
  },
  title: {
    marginLeft: 8,
  },
  breadcrumb: {
    marginTop: 2,
    marginBottom: 18,
  },
  linkVersaoClassica: {
    marginLeft: "auto",
  },
  icone: {
    height: "28px !important",
    width: "32px !important",
  },
  appendToTitle: {
    marginLeft: 8,
  },
})(Subheader);
