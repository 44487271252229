import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { withStyles } from "@material-ui/core";

import useLista from "../hooks/useLista";
import { MultiSelect } from "../_common";
import { useTipoPlanoSpotter } from "../hooks";

const styles = {
  multiSelectWrapper: {
    width: 180,

    "@media (max-width: 1400px)": {
      width: 110,
    },
  },
};

function SelectPreVendedores({ onChange, isFiltroAtividadesNovo, classes }) {
  const [opcoes] = useLista("/api/pipeline/usuario1/listar?tipo=PreVendedor");
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    !!opcoes.length && (
      <div className={isFiltroAtividadesNovo ? classes.multiSelectWrapper : ""}>
        <MultiSelect
          floatingLabelText={
            isSpotterFull
              ? intl.formatMessage({
                  defaultMessage: "Pré-vendedores",
                })
              : intl.formatMessage({
                  defaultMessage: "Usuários",
                })
          }
          initialItems={opcoes.map(opcao => ({
            ...opcao,
            checked: true,
          }))}
          onChange={ids => onChange(ids.length === opcoes.length ? [] : ids)}
          width={isFiltroAtividadesNovo ? "100%" : 180}
        />
      </div>
    )
  );
}

SelectPreVendedores.propTypes = {
  onChange: PropTypes.func,
  isFiltroAtividadesNovo: PropTypes.bool,
};

export default withStyles(styles)(SelectPreVendedores);
