import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, useField } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import { MenuItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddCircle from "@material-ui/icons/AddCircle";
import useSWR from "swr";
import axios from "axios";
import { useUsuario } from "../hooks";

SelectRegra.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  regras: PropTypes.array,
  disableItem: PropTypes.func,
};

export default function SelectRegra({ disableItem = x => !x, name = "regras", ...props }) {
  const intl = useIntl();
  const url = "/api/pipeline/SegmentacaoRegra/listar";
  const [field] = useField({ name, ...props });
  const { data } = useSWR(props.regras ? null : url);
  const [regras, setRegras] = useState(data?.filter(r => !r.usuarioId));
  const { isGerente } = useUsuario();

  const open = async e => {
    const valor = e.value;
    if (valor === undefined) {
      const { data: novaLista } = await axios.get(url);
      setRegras(novaLista?.filter(r => !r.usuarioId));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: novaLista } = await axios.get(url);
      setRegras(novaLista?.filter(r => !r.usuarioId));
    };

    if (data === undefined) fetchData();
    else if (data.length < field.value) fetchData();
  }, []);

  return (
    <Field
      component={TextField}
      select
      name={name}
      InputLabelProps={field.value ? { shrink: true } : null}
      {...props}
      label={intl.formatMessage({ defaultMessage: "Regra de Segmentação" })}
      onClick={({ target }) => open(target)}
    >
      <MenuItem>{intl.formatMessage({ defaultMessage: "Nenhuma" })}</MenuItem>
      {(regras || props.regras || []).map(({ id, nome }) => (
        <MenuItem key={id} value={id} disabled={disableItem(id)}>
          {nome}
        </MenuItem>
      ))}

      {isGerente && (
        <MenuItem
          style={{
            borderTop: " 1px solid lightgrey",
          }}
          component={Link}
          to="/spotter/configuracoes/regras-segmentacao"
          target="_blank"
        >
          <AddCircle style={{ color: "#457AB7", marginRight: 12 }} />
          <Typography
            style={{
              color: "#457AB7",
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
            }}
          >
            {intl.formatMessage({ defaultMessage: "Criar novo" })}
          </Typography>
        </MenuItem>
      )}
    </Field>
  );
}
