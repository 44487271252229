import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { AsyncWithSearch } from "../AsyncSelect";
import { usePermission } from "../../hooks";
import permissions from "../../_common/permissions";

const URL_PADRAO = "/api/pipeline/grupo/listar";
function SelectGroup(props) {
  const intl = useIntl();
  const temPermissao = usePermission(permissions.GRUPO);

  return (
    <AsyncWithSearch
      enableSearch
      getLabel={props.getLabel ? props.getLabel : item => item.nome}
      id="select-grupo"
      label={intl.formatMessage({ defaultMessage: "Grupo" })}
      url={props.url ? props.url : URL_PADRAO}
      criarNovo={temPermissao}
      urlCriarNovo={"/spotter/equipe/grupos"}
      {...props}
    />
  );
}

SelectGroup.propTypes = {
  url: PropTypes.string,
  getLabel: PropTypes.func,
  options: PropTypes.array,
};

export default SelectGroup;
