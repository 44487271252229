import React, { useRef, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Menu as MMenu, IconButton } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";

export default function VertMenu({ children }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const onClose = () => setOpen(false);

  if (!children) return null;

  return (
    <Fragment>
      <IconButton buttonRef={ref} onClick={() => setOpen(true)} id="id-more-leads">
        <MoreVert />
      </IconButton>
      <MMenu disableAutoFocusItem keepMounted anchorEl={ref.current} open={open} onClose={onClose}>
        {typeof children === "function" ? children({ onClose, ref }) : children}
      </MMenu>
    </Fragment>
  );
}

VertMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
