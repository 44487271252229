import React from "react";
import { useField } from "formik";

import SelectMotivoReuniao from "./SelectMotivoReuniao";

function FormikSelectMotivoReuniao(props) {
  const [field, meta] = useField({ name: "motivoId", ...props });

  return <SelectMotivoReuniao {...field} {...meta} {...props} />;
}

export default FormikSelectMotivoReuniao;
