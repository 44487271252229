import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";
import { defineMessage, useIntl } from "react-intl";
import {
  Table as MTable,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  withStyles,
} from "@material-ui/core";

import { InfiniteScroll, Loading } from "../_common";
import { useOrderBy, usePaginatedList } from "../hooks";

const message = defineMessage({ defaultMessage: "Não há itens para exibir" });

function SortedTable({
  initialOrder,
  onFetch,
  funilId,
  headers,
  containerId,
  classes = {},
  fetchRef,
  children,
  noItemsMessage = message,
}) {
  const [orderBy, asc, sort] = useOrderBy(initialOrder ? initialOrder.path : headers[0].path);
  const [args, setArgs] = useState({ orderBy, asc });
  const [content, fetch, loading, hasMore] = usePaginatedList(onFetch, args, funilId);

  const intl = useIntl();

  useEffect(
    () => {
      if (fetchRef) fetchRef.current = fetch;
    },
    [fetchRef]
  );

  useEffect(
    () => {
      setArgs({ orderBy, asc });
    },
    [orderBy, asc]
  );

  return (
    <InfiniteScroll
      loadMore={() => fetch(false)}
      initialLoad={false}
      hasMore={hasMore}
      loader={null}
      useWindow={false}
      queryselector={`#${containerId}`}
      style={{ width: "100%" }}
    >
      <MTable className={classes.table}>
        {!headers.length ? (
          headers
        ) : (
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers.map(({ text, path, style }) => (
                <TableCell key={path} onClick={() => sort(path)} style={style}>
                  <TableSortLabel active={path === orderBy} direction={asc ? "desc" : "asc"}>
                    {text}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {!content.length ? (
            <TableRow>
              <TableCell align="center" colSpan={headers.length} className={classes.loader}>
                {loading ? <Loading isLoading /> : intl.formatMessage(noItemsMessage)}
              </TableCell>
            </TableRow>
          ) : (
            <React.Fragment>
              {content.map(item => children({ ...item, fetch }))}
              {loading &&
                [1, 2, 3].map(i => (
                  <TableRow key={i}>
                    <TableCell colSpan={headers.length}>
                      <ContentLoader height={56} width={560}>
                        <rect x="0" y="0" rx="0" ry="0" height="100%" width="100%" />
                      </ContentLoader>
                    </TableCell>
                  </TableRow>
                ))}
            </React.Fragment>
          )}
        </TableBody>
      </MTable>
    </InfiniteScroll>
  );
}

SortedTable.propTypes = {
  /** for material-ui style overwriting */
  classes: PropTypes.object,
  /** receives the item spread as props */
  children: PropTypes.func.isRequired,
  /** object containing headers specification */
  headers: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    PropTypes.node,
  ]).isRequired,
  /** function to fetch data */
  onFetch: PropTypes.func.isRequired,
  funilId: PropTypes.number,
  /** optional initial order */
  initialOrder: PropTypes.shape({
    asc: PropTypes.bool,
    path: PropTypes.string,
  }),
  /** container element which holds the scroll */
  containerId: PropTypes.string,
  /** holds a reference to the fetch function, allowing programatically calling fetch */
  fetchRef: PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.object, PropTypes.func]) }),
  noItemsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles({
  table: {},
  tableHead: {},
  loader: {},
})(SortedTable);
// export default Table;
