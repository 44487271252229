import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Button, Typography, Divider } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { Site, Fone } from "../../../components";
import { InfoItem } from "../../../_common";
import { cpf, cpfMask, cnpjMask } from "../../TextMaskCpfCnpj";
import { DEFAULT_COUNTRY } from "../../../_common/utils/phone/maskConfig";
import { formatPhoneMask } from "../../../_common/utils/phone";

OrganizacaoInfo.propTypes = {
  org: PropTypes.shape({
    id: PropTypes.number,
    nome: PropTypes.string,
    idPublico: PropTypes.string,
    endereco: PropTypes.object,
    cpfCnpj: PropTypes.string,
    site: PropTypes.string,
    telefones: PropTypes.array,
  }).isRequired,
  isPublic: PropTypes.bool,
};

/**
 * Deve apenas receber uma `org` e mostrar os dados.
 * Este componente é reutilizado na tela de detalhe publico da organização.
 */
function OrganizacaoInfo({ org, isPublic = false, classes }) {
  const intl = useIntl();
  const [showMore, setShowMore] = useState(false);
  const { orgId } = useParams();
  const resolvedOrgId = isPublic ? org.idPublico : org.id;
  const isOrgPage = `${orgId}` === `${resolvedOrgId}`;
  const selfLink = isPublic ? `/public/organizacao/${resolvedOrgId}` : `/spotter/organizacao/${resolvedOrgId}`;

  if (!org) return null;

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} className={classes.name}>
        {isOrgPage ? (
          org.nome
        ) : (
          <Link className={classes.nameLink} to={selfLink}>
            {org.nome}
          </Link>
        )}
      </Grid>
      {org.telefones?.length > 0 && (
        <Grid item xs={12}>
          {org.telefones.map(telefone => (
            <Fone
              key={telefone.id}
              value={formatPhoneMask({
                phone: telefone.numero,
                ddi: telefone.ddi === "" ? DEFAULT_COUNTRY : telefone.ddi,
              })}
              ddi={telefone.ddi}
              tipo={String(telefone.tipo.id)}
            />
          ))}
        </Grid>
      )}

      {!showMore && (
        <Grid item xs={12}>
          <Button color="primary" onClick={() => setShowMore(true)}>
            {intl.formatMessage({ defaultMessage: "Mais" })}
          </Button>
        </Grid>
      )}

      {showMore && (
        <>
          <Grid item xs={12}>
            <div className={classes.sideSpace}>
              <Divider light />
            </div>
          </Grid>
          {!isPublic && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary" className={classes.sideSpace}>
                {intl.formatMessage({ defaultMessage: "Link Público" })}
              </Typography>
              <Site value={`${window.location.origin}/public/organizacao/${org.idPublico}`} />
              {!org.site && (
                <div className={classes.sideSpace}>
                  <Divider light />
                </div>
              )}
            </Grid>
          )}

          {org.site && (
            <>
              <Grid item xs={12}>
                <div className={classes.sideSpace}>
                  <Divider light />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary" className={classes.sideSpace}>
                  {intl.formatMessage({ defaultMessage: "Site" })}
                </Typography>
                <Site value={org.site} />
                <div className={classes.sideSpace}>
                  <Divider light />
                </div>
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <InfoItem
              id="id-cep-organizacao"
              label={intl.formatMessage({ defaultMessage: "CEP" })}
              value={org.endereco?.cep || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-pais-organizacao"
              label={intl.formatMessage({ defaultMessage: "País" })}
              value={org.endereco?.pais?.descricao || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-estado-organizacao"
              label={intl.formatMessage({ defaultMessage: "Estado" })}
              value={org.endereco?.estado?.descricao || "-"}
            />
          </Grid>
          <Grid item xs={6}>
            <InfoItem
              id="id-cidade-organizacao"
              label={intl.formatMessage({ defaultMessage: "Cidade" })}
              value={org.endereco?.cidade?.descricao || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-logradouro-organizacao"
              label={intl.formatMessage({ defaultMessage: "Logradouro" })}
              value={org.endereco?.logradouro || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-numero-organizacao"
              label={intl.formatMessage({ defaultMessage: "Número" })}
              value={org.endereco?.numero || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-complemento-organizacao"
              label={intl.formatMessage({ defaultMessage: "Complemento" })}
              value={org.endereco?.complemento || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-bairro-organizacao"
              label={intl.formatMessage({ defaultMessage: "Bairro" })}
              value={org.endereco?.bairro || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoItem
              id="id-cpf-cnpj-organizacao"
              label={intl.formatMessage({ defaultMessage: "CPF/CNPJ" })}
              value={org?.cpfCnpj?.length > cpf.length - 3 ? cnpjMask(org?.cpfCnpj) : cpfMask(org?.cpfCnpj)}
            />
          </Grid>
        </>
      )}

      {showMore && (
        <Grid item xs={12}>
          <Button color="primary" onClick={() => setShowMore(false)}>
            {intl.formatMessage({ defaultMessage: "Menos" })}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(() => ({
  name: { wordBreak: "break-all", paddingLeft: "24px !important" },
  nameLink: { color: "#337ab7 !important" },
  sideSpace: { paddingLeft: 20, paddingRight: 20 },
}))(OrganizacaoInfo);
