import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { get } from "dot-prop-immutable";

import { useIntl } from "react-intl";
import { useLista } from "../hooks";
import { SelectScrollableFormik } from "../_common";

function SelectSubOrigem({ field, form, name, origem, classes, ...props }) {
  const [subOrigens, loading] = useLista("/api/pipeline/suborigem/listar?somenteAtivos=true");
  const [options, setOptions] = useState([]);
  const firstRender = React.useRef(true);
  const intl = useIntl();

  useEffect(
    () => {
      if (firstRender.current) firstRender.current = false;
      else {
        const subOrigem = get(form.values, field.name);
        if (subOrigem && subOrigem.origemId !== origem) form.setFieldValue(field.name, "");
      }
    },
    [origem]
  );

  useEffect(
    () => {
      setOptions(subOrigens.filter(({ origemId }) => origemId === origem));
    },
    [origem, subOrigens]
  );

  return (
    <SelectScrollableFormik disabled={loading || !origem} name={name} {...props} field={field} form={form}>
      <MenuItem value="">
        <em>{intl.formatMessage({ defaultMessage: "Nenhuma" })}</em>
      </MenuItem>
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.descricao}
        </MenuItem>
      ))}
    </SelectScrollableFormik>
  );
}

SelectSubOrigem.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  origem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
};

export default SelectSubOrigem;
