import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useIntl } from "react-intl";

import { Flex, Tipografia, IconeCheck } from "../_common";
import useLista from "../hooks/useLista";

const ICONS_PER_PAGE = 6;

const getDescricaoAtividade = (tipoAtividade, intl) => {
  const { id, descricao } = tipoAtividade;
  switch (id) {
    case 9:
      return intl.formatMessage({ defaultMessage: "Retorno de Ligação" });
    case 10:
      return intl.formatMessage({ defaultMessage: "Verificar Disponibilidade Para Reunião" });
    case 11:
      return intl.formatMessage({ defaultMessage: "Enviar E-mail" });
    case 12:
      return intl.formatMessage({ defaultMessage: "Outros" });
    case 13:
      return intl.formatMessage({ defaultMessage: "Continuar Filtro" });
    default:
      return descricao;
  }
};

function SeletorTipo({ onChange, hideLabel, label }) {
  const intl = useIntl();
  const [opcoes] = useLista("/api/pipeline/TipoAtividade/Listar?atividadePendente=true");
  const [page, setPage] = useState(0);
  const [tipos, setTipos] = useState([]);

  useEffect(
    () => {
      setTipos(opcoes.map(({ id }) => id));
    },
    [opcoes]
  );

  useEffect(
    () => {
      onChange(tipos.length === opcoes.length ? [] : tipos);
    },
    [tipos]
  );

  return (
    <Flex flexDirection="column" justifyContent="center" style={{ padding: 8, minWidth: 288 }}>
      {!hideLabel && (
        <Tipografia style={{ paddingBottom: 2 }} tipo="notaLegendas" cor="darkSecondaryText">
          {label}
        </Tipografia>
      )}

      <Flex alignItems="center">
        {page > 0 &&
          opcoes.length > ICONS_PER_PAGE && (
            <IconButton style={{ justifySelf: "flex-start", width: 32, height: 32 }} onClick={() => setPage(page - 1)}>
              <KeyboardArrowLeft />
            </IconButton>
          )}
        {opcoes.slice(page * ICONS_PER_PAGE, (page + 1) * ICONS_PER_PAGE).map(({ id, icone, descricao }, index) => (
          <span style={{ marginLeft: index === 0 ? 0 : 4, marginRight: 4 }} key={id}>
            <IconeCheck
              id={`tipo-atividade-${id}`}
              icone={icone}
              tooltip={getDescricaoAtividade({ id, descricao }, intl)}
              checked={tipos.indexOf(id) > -1}
              handleOnClick={() =>
                setTipos(tipos.indexOf(id) > -1 ? tipos.filter(estado => estado !== id) : [...tipos, id])
              }
            />
          </span>
        ))}
        {opcoes.length > ICONS_PER_PAGE &&
          (page + 1) * ICONS_PER_PAGE < opcoes.length && (
            <IconButton style={{ justifySelf: "flex-end", width: 32, height: 32 }} onClick={() => setPage(page + 1)}>
              <KeyboardArrowRight />
            </IconButton>
          )}
      </Flex>
    </Flex>
  );
}

SeletorTipo.propTypes = {
  onChange: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
};

export default SeletorTipo;
