import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import { get } from "dot-prop-immutable";
import { Button, DialogTitle, DialogContent, DialogActions, withStyles, Icon } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import LabelIcon from "@material-ui/icons/Label";
import BusinessIcon from "@material-ui/icons/Business";
import axios from "axios";

import OrganizacaoEditFormBase from "../EditForm/Base";
import { ORG_BASE_FORM_INITIAL_VALUES, ORG_OBSERVACAO_FORM_INITIAL_VALUES } from "../EditForm/constants";
import { FormikErrorScroller } from "../..";
import OrganizacaoEditFormObservacao from "../EditForm/Observacao";
import OrganizacaoEditFormCamposPersonalizados from "../EditForm/CamposPersonalizados";
import { createSnackbarAPIException, Flex, Loading, ExpansionPanelModal, AjaxBlackout } from "../../../_common";
import { defaultGetElement } from "../../FormikErrorScroller";
import { useGet, useTipoPlanoSpotter } from "../../../hooks";
import { LeadEnderecoForm } from "../../../pages/lead/components";

const initializeFormValues = (camposPersonalizados = []) => ({
  ...ORG_BASE_FORM_INITIAL_VALUES(),
  ...ORG_OBSERVACAO_FORM_INITIAL_VALUES(),
  camposPersonalizados,
});

async function validateUniqueCampoPersinalizado(campo) {
  const { id, valor, valores } = campo;
  let isUnique = false;
  try {
    const { data } = await axios.get(
      `/api/pipeline/CampoPersonalizado/ValidarUnicidade?id=${id}&valor=${valor || valores}&isOrganizacao=true`
    );
    isUnique = data;
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }

  return !isUnique ? "O valor neste campo deve ser único entre as Organizações" : undefined;
}

function OrganizacaoCadastrarModalForm({ onCancel, onConfirm, classes }) {
  const intl = useIntl();
  const [campos, isLoadingCampos] = useGet("/api/pipeline/CampoPersonalizado/ListasParaEdicao?isOrganizacao=true");
  const initialValues = initializeFormValues(campos);
  const isSpotterFull = useTipoPlanoSpotter();

  function submitHandler(values, { setSubmitting, setFieldError }) {
    async function submit() {
      AjaxBlackout.Show();

      const isValid = await validateCamposPersonalizados(values.camposPersonalizados, setFieldError);

      if (!isValid) {
        setSubmitting(false);
        AjaxBlackout.Hide();
        return;
      }

      const organizacao = {
        ...values,
        cpfCnpj: values.cpfCnpj?.replace(/\D/g, ""),
        telefones: values.telefones
          .filter(telefone => telefone.numero)
          .map(tel => ({ ...tel, numero: retirarMascara(tel.numero) })),
      };

      try {
        const { data: organizacaoId } = await axios.post("/api/pipeline/Organizacao/Salvar", organizacao);
        onConfirm(organizacaoId);
      } catch (err) {
        createSnackbarAPIException(err);
        setSubmitting(false);
        AjaxBlackout.Hide();
      }
    }
    /**
     * Implementado dessa forma para evitar a
     * submissão do form múltiplas vezes.
     * Formik não atualiza como esperado a variável
     * isSubmitting quando a função onSubmit é
     * assíncrona.
     * @see https://github.com/formium/formik/issues/1957#issuecomment-638470426
     */
    submit();
  }

  function retirarMascara(numero) {
    return numero
      .toString()
      .replace(/[( )-]+/g, "")
      .trim();
  }

  async function validateCamposPersonalizados(camposPersonalizados, setFieldError) {
    const validacoesPromises = camposPersonalizados.map(async (campo, index) => {
      if (!campo.unico) {
        return null;
      }
      const message = await validateUniqueCampoPersinalizado(campo);
      const fieldName = `camposPersonalizados.${index}`;

      return message ? { fieldName, message } : null;
    });

    if (!validacoesPromises?.length) return true;

    const errors = await Promise.all(validacoesPromises);

    const errorFields = [];

    errors.forEach(errObj => {
      if (!errObj) return;

      const { fieldName, message } = errObj;
      if (message) {
        errorFields.push(fieldName);
        setFieldError(fieldName, message);
      }
    });

    const isValid = errorFields.length <= 0;
    if (!isValid) {
      const field = defaultGetElement(errorFields[0]);
      field.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    return isValid;
  }

  function getDadosOrgHasErrors(errors, submitCount) {
    const fieldNames = ["nome", "cpfCnpj", "telefones", "site"];
    return submitCount > 0 && fieldNames.some(name => Boolean(get(errors, name)));
  }

  return (
    <Flex flexDirection="column" style={{ width: 756 }}>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Cadastrar Organização" })}</DialogTitle>
      <Loading isLoading={isLoadingCampos}>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={submitHandler}>
          {({ isSubmitting, handleReset, errors, submitCount }) => {
            const dadosOrgHasErrors = getDadosOrgHasErrors(errors, submitCount);
            const enderecoHasErrors = submitCount > 0 && !!errors.endereco;
            const camposPersonalizadosHasErrors = submitCount > 0 && !!errors.camposPersonalizados;

            return (
              <Form id="form-cadastro-organizacao" noValidate>
                <FormikErrorScroller />
                <DialogContent className={classes.dialogContent}>
                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      defaultExpanded
                      title={intl.formatMessage({ defaultMessage: "Dados da Empresa/Organização" })}
                      icon={<BusinessIcon />}
                      hasError={dadosOrgHasErrors}
                    >
                      <OrganizacaoEditFormBase />
                    </ExpansionPanelModal>
                  </div>

                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      title={intl.formatMessage({ defaultMessage: "Endereço" })}
                      icon={<PlaceIcon />}
                      hasError={enderecoHasErrors}
                    >
                      <LeadEnderecoForm />
                    </ExpansionPanelModal>
                  </div>
                  {isSpotterFull && (
                    <div className={classes.sectionWrapper}>
                      <ExpansionPanelModal
                        title={intl.formatMessage({ defaultMessage: "Campos Personalizados" })}
                        icon={<Icon className="fa fa-pencil-square-o" />}
                        hasError={camposPersonalizadosHasErrors}
                      >
                        <Flex flexDirection="column">
                          <OrganizacaoEditFormCamposPersonalizados campos={campos} />
                        </Flex>
                      </ExpansionPanelModal>
                    </div>
                  )}

                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      title={intl.formatMessage({ defaultMessage: "Observações" })}
                      icon={<LabelIcon />}
                    >
                      <OrganizacaoEditFormObservacao />
                    </ExpansionPanelModal>
                  </div>
                </DialogContent>

                <DialogActions>
                  <Button
                    id="form-cadastro-organizacao_cancel-button"
                    disabled={isSubmitting}
                    onClick={() => {
                      handleReset();
                      onCancel();
                    }}
                  >
                    {intl.formatMessage({ defaultMessage: "Cancelar" })}
                  </Button>

                  <Button
                    id="form-cadastro-organizacao_save-button"
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                  >
                    {intl.formatMessage({ defaultMessage: "Cadastrar" })}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </Flex>
  );
}

OrganizacaoCadastrarModalForm.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default withStyles({
  expansionPanelRoot: {
    overflow: "visible",
  },
  expansionPanelDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  dialogContent: {
    paddingTop: "0 !important",
  },
  sectionTitle: {
    marginBottom: 8,
  },
  sectionWrapper: {
    paddingBottom: 16,
  },
})(OrganizacaoCadastrarModalForm);
