import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Launch from "@material-ui/icons/Launch";
import { Tooltip } from "@material-ui/core";

import { normalizeLinkProtocol } from "../_common/utils/string";
import Comp from "./HoverActions";

function Site({ value, classes, tooltip }) {
  const intl = useIntl();
  return (
    <Comp value={value || "-"} classes={classes} tooltip={tooltip}>
      <Tooltip title={intl.formatMessage({ defaultMessage: "Abrir website" })} placement="bottom">
        <Launch
          id="abrirSite"
          onClick={() => window.open(normalizeLinkProtocol(value), "_blank")}
          fontSize="small"
          color="primary"
        />
      </Tooltip>
    </Comp>
  );
}

Site.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  tooltip: PropTypes.string,
};

export default Site;
