import React from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText } from "@material-ui/core";
import v4 from "uuid/v4";

import { Loading } from "../_common";
import { maybeCallback } from "../_common/utils/fp";

function renderInput(inputProps) {
  const { InputProps, classes, ref, isLoading, error, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        endAdornment: isLoading ? <Loading className={classes.loading} isLoading size={24} /> : null,
        ...InputProps,
      }}
      error={!!error}
      {...other}
    />
  );
}

renderInput.propTypes = {
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = ((selectedItem && selectedItem.descricao) || "").indexOf(suggestion.descricao) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id || suggestion.descricao}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.descricao}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

function wordContains(string, ...content) {
  const normalize = word =>
    deburr(word)
      .trim()
      .toLocaleLowerCase();
  const cleanWord = normalize(string);
  return content.every(word => cleanWord.indexOf(normalize(word)) > -1);
}

function getSuggestions(value, suggestions, size, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const keep = count < size && wordContains(suggestion.descricao, inputValue);
        if (keep) count += 1;
        return keep;
      });
}

function SelectAutoComplete({
  label,
  placeholder,
  options,
  value,
  onChange,
  onBlur,
  onKeyUp,
  disabled,
  classes,
  size = 5,
  hasScroll = false,
  paperMaxHeight = "150px",
  required,
  error,
  isLoading = false,
  shrinkLabel = true,
  showEmpty = true,
  inputProps = {},
  dataScrollAnchor,
}) {
  // uid para prevenir o autocomplete do chrome
  const [uid, setUid] = React.useState();

  React.useEffect(() => {
    setUid(v4());
  }, []);

  return (
    <div className={classes.root} data-scroll-anchor={dataScrollAnchor}>
      <Downshift selectedItem={(value && value.descricao) || ""} onChange={onChange}>
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem,
        }) => {
          const { onBlur: _onBlur, onChange: _onChange, onFocus, onKeyUp: _onKeyUp, ...restInputProps } = getInputProps(
            {
              onChange: event => {
                if (event.target.value === "") {
                  clearSelection();
                }
              },
              onKeyUp: event => {
                maybeCallback(onKeyUp)(event);
              },
              onBlur: event => {
                maybeCallback(onBlur)(event);
              },
              onFocus: openMenu,
              placeholder,
              id: uid, // prevenir o autocomplete do chrome
              autoComplete: "new-password", // prevenir o autocomplete do chrome
            }
          );

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                label,
                InputLabelProps: getLabelProps({ shrink: shrinkLabel || undefined }), // undefined keeps the default behavior
                InputProps: {
                  onChange: _onChange,
                  onBlur: _onBlur,
                  onKeyUp: _onKeyUp,
                  onFocus,
                  disabled,
                },
                inputProps: { ...restInputProps, ...inputProps },
                required,
                error,
                isLoading,
              })}
              {error && (
                <FormHelperText style={{ marginTop: 0 }} error>
                  {error}
                </FormHelperText>
              )}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper
                    style={hasScroll ? { maxHeight: paperMaxHeight, overflowY: "scroll" } : null}
                    className={classes.paper}
                    square
                  >
                    {getSuggestions(inputValue, options, size, { showEmpty }).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.descricao }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}

SelectAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.number,
    descricao: PropTypes.string,
  }),
  hasScroll: PropTypes.bool,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  inputProps: PropTypes.object,
  shrinkLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  required: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  dataScrollAnchor: PropTypes.string,
  paperMaxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showEmpty: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: 4,
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  loading: {
    height: "auto !important",
    width: "fit-content !important",
    position: "absolute !important",
    right: 6,
  },
})(SelectAutoComplete);
