import React from "react";
import PropTypes from "prop-types";
import NumberMask from "react-number-format";

PercentInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

function PercentInput({ inputRef, ...rest }) {
  return (
    <NumberMask
      getInputRef={inputRef}
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      fixedDecimalScale
      allowEmptyFormatting
      suffix=" %"
      isAllowed={({ floatValue }) => floatValue <= 100}
      {...rest}
    />
  );
}

export default React.memo(PercentInput);
