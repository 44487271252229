import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Tipografia, CorCheck } from "../_common";

function SelectEstadoAtividade({ estados, setEstados, theme, hideLabel, contextFilterId }) {
  const intl = useIntl();
  const {
    palette: { status },
  } = theme;
  const opcoes = [
    { id: 1, cor: status.red, tooltip: intl.formatMessage({ defaultMessage: "Atrasadas" }) },
    { id: 2, cor: status.yellow, tooltip: intl.formatMessage({ defaultMessage: "Para hoje" }) },
    { id: 3, cor: status.gray, tooltip: intl.formatMessage({ defaultMessage: "Planejadas" }) },
    { id: 4, cor: status.green, tooltip: intl.formatMessage({ defaultMessage: "Concluídas" }) },
  ];

  const idCheck = id => (contextFilterId ? `${id}|${contextFilterId}` : id);

  return (
    <Flex flexDirection="column" justifyContent="center" style={{ padding: 8 }}>
      {!hideLabel && (
        <Tipografia style={{ paddingBottom: 2 }} tipo="notaLegendas" cor="darkSecondaryText">
          <FormattedMessage defaultMessage="Filtrar por status" />
        </Tipografia>
      )}

      <Flex>
        {opcoes.map(({ cor, tooltip, id }) => (
          <CorCheck
            key={idCheck(id)}
            id={idCheck(id)}
            cor={cor}
            tooltip={tooltip}
            checked={estados.indexOf(id) > -1}
            handleOnClick={() =>
              setEstados(estados.indexOf(id) > -1 ? estados.filter(estado => estado !== id) : [...estados, id])
            }
          />
        ))}
      </Flex>
    </Flex>
  );
}

SelectEstadoAtividade.propTypes = {
  estados: PropTypes.arrayOf(PropTypes.number),
  setEstados: PropTypes.func,
  theme: PropTypes.object,
  hideLabel: PropTypes.bool,
  contextFilterId: PropTypes.number,
};

export default withTheme()(SelectEstadoAtividade);
