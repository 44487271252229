import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { TEMPERATURA, LABELS_TEMPERATURA, notaTemperaturaPraQualificacao } from "../../_common/constantes";
import { AsyncWithSearch } from "../AsyncSelect";

function SelectTemperatura(props) {
  const intl = useIntl();

  const OPTIONS = useMemo(
    () =>
      Object.values(TEMPERATURA)
        .filter(Boolean)
        .map(temperatura => ({
          id: notaTemperaturaPraQualificacao(temperatura),
          descricao: intl.formatMessage(LABELS_TEMPERATURA[temperatura]),
        })),
    []
  );

  return (
    <AsyncWithSearch
      label={intl.formatMessage({ defaultMessage: "Qualificação" })}
      type="multiple"
      options={OPTIONS}
      {...props}
    />
  );
}

export default SelectTemperatura;
