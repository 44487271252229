import React from "react";
import PropTypes from "prop-types";
import { Paper, Divider, withStyles } from "@material-ui/core";
import DashboardInfo from "../pages/dashboard/components/DashboardInfo/index";

import { Flex, Tipografia } from "../_common";
import Tile from "./Tile";

function Pannel({
  title,
  subtitle,
  actions,
  height,
  width,
  children,
  classes,
  id,
  relatorio,
  contentId = `${id}-content`,
}) {
  return (
    <Tile height={height} width={width} id={id}>
      <Paper className={classes.root}>
        <Flex justifyContent="space-between" className={classes.header}>
          <Flex className={classes.title}>
            <Tipografia tipo="tituloCard">{title}</Tipografia>
            {subtitle && (
              <Tipografia tipo="tituloCard" cor="darkSecondaryText">
                &nbsp;| {subtitle}
              </Tipografia>
            )}
            <DashboardInfo titulo={title} relatorio={relatorio} />
          </Flex>
          {actions}
        </Flex>
        <Divider />
        <Flex id={contentId} className={classes.content}>
          {children}
        </Flex>
      </Paper>
    </Tile>
  );
}

Pannel.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  id: PropTypes.string,
  contentId: PropTypes.string,
  relatorio: PropTypes.number,
};

export default withStyles(theme => ({
  root: {
    minHeight: 100,
    backgroundColor: "#fff",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    flexShrink: 0,
    height: 56,
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
  },
  title: {
    width: "100%",
  },
  content: {
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}))(Pannel);
