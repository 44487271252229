import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { Field, useField } from "formik";
import { MenuItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import AddCircle from "@material-ui/icons/AddCircle";
import useSWR from "swr";

function SelectQuestionario({ tipos, name, disableRegra = x => !x, onChangeTipo = x => x, ...props }) {
  const intl = useIntl();
  const [field] = useField({ name, ...props });
  const { data: questionarios, mutate } = useSWR(
    props.questionarios ? null : `/api/pipeline/questionario/listar?${tipos.map(t => `tipos[]=${t}`).join("&")}`
  );
  const tipo = useMemo(() => questionarios?.find(q => q.id === field.value)?.tipo, [field.value]);
  useEffect(
    () => {
      onChangeTipo(tipo);
    },
    [tipo]
  );

  const open = async () => {
    mutate();
  };

  return (
    <Field
      component={TextField}
      select
      name={name}
      InputLabelProps={field.value ? { shrink: true } : null}
      {...props}
      onClick={open}
    >
      {(questionarios || props.questionarios || []).map(({ id, descricao }) => (
        <MenuItem key={id} value={id} disabled={disableRegra(id)}>
          {descricao}
        </MenuItem>
      ))}
      <MenuItem
        style={{
          borderTop: " 1px solid lightgrey",
        }}
        component={Link}
        to="/spotter/configuracoes/filtros"
        target="_blank"
      >
        <AddCircle style={{ color: "#457AB7", marginRight: 12 }} />
        <Typography
          style={{
            color: "#457AB7",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
          }}
        >
          {intl.formatMessage({ defaultMessage: "Criar novo" })}
        </Typography>
      </MenuItem>
    </Field>
  );
}

SelectQuestionario.propTypes = {
  tipos: PropTypes.arrayOf(PropTypes.number),
  onChangeTipo: PropTypes.func,
  name: PropTypes.string,
  questionarios: PropTypes.array,
  disableRegra: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SelectQuestionario;
