import { useState, useEffect } from "react";
import useSWR from "swr";
import { useDebounce } from "../../../hooks";

export default function useCamposSemelhantesOrganizacao({ orgId, campo, valor }) {
  const [debouncedSearch, setDebouncedSearch] = useState(valor);
  const debouncedValue = useDebounce(debouncedSearch);
  const [semelhantes, setSemelhantes] = useState({ orgs: [], quantidade: 0 });

  const { data, isValidating } = useSWR(
    () =>
      debouncedValue.length >= 3
        ? `/api/pipeline/organizacao/BuscarSemelhantes?valor=${debouncedValue}&campo=${campo}&id=${orgId}`
        : null
  );

  useEffect(
    () => {
      if (data) {
        setSemelhantes({ orgs: data, quantidade: data?.length });
      }
    },
    [data]
  );

  return {
    semelhantes,
    isLoading: isValidating,
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
  };
}
