import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "dot-prop-immutable";
import { useIntl } from "react-intl";
import { AsyncWithSearch } from "../components/AsyncSelect";

import { useTipoPlanoSpotter } from "../hooks";

function SelectUsuario({ value, onChange, field, form, ...props }) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const [valor, setUsuario] = useState(0);
  const URL = "/api/pipeline/usuario1/ListarPreVendedoresEVendedores?somenteAtivos=true";

  const onChangeUsuario = v => {
    if (field) form.setFieldValue(field.name, v);
    else onChange(v);
    setUsuario(v);
  };

  useEffect(() => {
    if (value) setUsuario(value);
  }, []);

  return (
    <AsyncWithSearch
      enableSearch
      getLabel={item => item.descricao}
      getId={item => item.id}
      label={
        isSpotterFull
          ? intl.formatMessage({ defaultMessage: "Vendedor" })
          : intl.formatMessage({ defaultMessage: "Responsável pela venda" })
      }
      url={URL}
      value={valor}
      onChange={v => onChangeUsuario(v.target.value)}
      error={!!get(form.errors, field.name)}
      {...props}
    />
  );
}

SelectUsuario.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  field: PropTypes.object,
  form: PropTypes.object,
  name: PropTypes.string,
};

export default SelectUsuario;
