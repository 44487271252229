import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { IconButton, withStyles } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";

import { Flex, Tipografia } from "../_common";

function formatarData(inicio, fim, agrupamento) {
  const def = () => {
    let descricaoDiaSemana = "";
    const intl = useIntl();
    if (Moment(inicio).isSame(Moment(), "day")) {
      descricaoDiaSemana = intl.formatMessage({ defaultMessage: "Hoje" });
    } else if (Moment(inicio).isSame(Moment().add(1, "day"), "day")) {
      descricaoDiaSemana = intl.formatMessage({ defaultMessage: "Amanhã" });
    } else if (Moment(inicio).isSame(Moment().add(-1, "day"), "day")) {
      descricaoDiaSemana = intl.formatMessage({ defaultMessage: "Ontem" });
    } else {
      descricaoDiaSemana = Moment(inicio).format("dddd");
    }

    return `${descricaoDiaSemana}, ${Moment(inicio).format("D [de] MMM [de] YYYY")}`;
  };

  return ({
    WEEK: () => `${Moment(inicio).format("DD [de] MMM")} - ${Moment(fim).format("DD [de] MMM [de] YYYY")}`,
    MONTH: () => Moment(inicio).format("MMMM [de] YYYY"),
    YEAR: () => Moment(inicio).format("YYYY"),
  }[agrupamento] || def)();
}

const format = (start, end, intervalo, agrupamento) => [
  Moment(start)
    .add(intervalo, agrupamento)
    .format("YYYY-MM-DD HH:mm:ss"),
  Moment(end)
    .add(intervalo, agrupamento)
    .format("YYYY-MM-DD HH:mm:ss"),
];

function SelectData({ inicio, fim, agrupamento, setIntervalo, classes }) {
  const data = useMemo(() => formatarData(inicio, fim, agrupamento), [inicio, fim, agrupamento]);

  return (
    <Flex>
      <Flex style={{ height: 30, paddingLeft: 12, width: 290 }}>
        <IconButton
          className={classes.botaoIcone}
          onClick={() => setIntervalo(...format(inicio, fim, -1, agrupamento))}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <Tipografia tipo="textoSidebar" cor="darkPrimaryText" className={classes.descricaoData}>
          {data}
        </Tipografia>
        <IconButton className={classes.botaoIcone} onClick={() => setIntervalo(...format(inicio, fim, 1, agrupamento))}>
          <KeyboardArrowRight fontSize="large" />
        </IconButton>
      </Flex>
    </Flex>
  );
}

SelectData.propTypes = {
  inicio: PropTypes.string,
  fim: PropTypes.string,
  agrupamento: PropTypes.string,
  setIntervalo: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles({
  icone: { height: "28px !important", width: "32px !important" },
  botaoIcone: {
    height: "38px !important",
    width: "34px !important",
    padding: "0px !important",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  descricaoData: { alignSelf: "center" },
})(SelectData);
