import React from "react";
import PropTypes from "prop-types";
import { CardContent, withStyles } from "@material-ui/core";

function OutlinedCardContent({ classes, children, ...props }) {
  return (
    <CardContent className={classes.outline} {...props}>
      {children}
    </CardContent>
  );
}

OutlinedCardContent.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles({
  outline: { border: "1px solid #E1E1E1", borderRadius: "10px", width: 580, marginBottom: 28 },
})(OutlinedCardContent);
