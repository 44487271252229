import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

const char = "\u2000";

const resolveMask = length => [...new Array(length).fill(/[0-9]/)];

function TextMaskNumber({ length = 3, inputRef, ...other }) {
  return (
    <MaskedInput
      guide={false}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={char}
      mask={resolveMask(length)}
      {...other}
    />
  );
}

TextMaskNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  length: PropTypes.number,
};

export default TextMaskNumber;
