import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogActions,
  Button,
  Link as MuiLink,
  withStyles,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { DialogTitleClose, Flex } from "../../../_common";

OrganizacaoSemelhantes.propTypes = {
  orgs: PropTypes.array,
  quantidade: PropTypes.number,
  onClickVincularSemelhante: PropTypes.func,
};

function OrganizacaoSemelhantes({ orgs, quantidade, onClickVincularSemelhante, classes }) {
  const intl = useIntl();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);

  return (
    <>
      <span id="org-semelhantes" className={classes.message} onClick={() => setIsOpen(true)}>
        {intl.formatMessage({
          defaultMessage: "Possui organizações semelhantes. Clique para visualizar.",
        })}
      </span>
      <Dialog id="org-semelhantes_modal" open={isOpen} onClose={onClose}>
        <DialogTitleClose onClose={onClose}>
          {intl.formatMessage({ defaultMessage: "Organizações Semelhantes" })}
        </DialogTitleClose>
        <DialogContent style={{ height: 328, width: 648, overflowY: "scroll", marginRight: 16 }}>
          {orgs.map((org, index) => (
            <React.Fragment key={org.id}>
              {!!index && <Divider />}
              <Flex className={classes.item} flexDirection="column" justifyContent="center">
                <Link to={`/spotter/organizacao/${org.id}`} target="_blank">
                  <MuiLink variant="h6" underline={"none"}>
                    {org.nome}
                  </MuiLink>
                </Link>
                {org.cpfCnpj && (
                  <Typography>
                    {intl.formatMessage({ defaultMessage: "CPF/CNPJ:" })} {org.cpfCnpj}
                  </Typography>
                )}

                {org.telefones?.[0]?.numero && (
                  <Typography>
                    {intl.formatMessage({ defaultMessage: "Telefone:" })} {org.telefones[0].numero}
                  </Typography>
                )}

                {org.site && (
                  <Typography>
                    {intl.formatMessage({ defaultMessage: "Site:" })} {org.site}
                  </Typography>
                )}

                <Typography>
                  {intl.formatMessage({ defaultMessage: "Cadastrado em:" })} {intl.formatDate(org.dataCadastro)}
                </Typography>
                {onClickVincularSemelhante && (
                  <Button
                    id={`org-semelhantes_vincular-button-${org.id}`}
                    className={classes.vincularButton}
                    color="primary"
                    onClick={() => onClickVincularSemelhante(org.id, onClose)}
                    variant="contained"
                  >
                    {intl.formatMessage({ defaultMessage: "Vincular" })}
                  </Button>
                )}
              </Flex>
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Typography color="textSecondary">
            {orgs.length < quantidade &&
              intl.formatMessage(
                { defaultMessage: "Há outras {amount} organizações semelhantes" },
                { amount: quantidade - orgs.length }
              )}
          </Typography>
          <Button id="org-semelhantes_dismiss-button" color="primary" onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(theme => ({
  message: {
    cursor: "pointer",
  },
  vincularButton: {
    alignSelf: "end",
  },
  item: {
    padding: 12,
    "&:hover": {
      background: theme.palette.backgroundRowHover,
    },
  },
  telefoneSiteWrapper: {
    display: "flex",
    "& p:nth-child(even)": {
      marginLeft: 24,
      "&:before": {
        content: '"|"',
        marginRight: 24,
      },
    },
  },
}))(OrganizacaoSemelhantes);
