import React from "react";
import { useField } from "formik";

import SelectMotivoDescarte from "./SelectMotivoDescarte";

function FormikSelectMotivoDescarte(props, padroes) {
  const [field, meta] = useField({ name: "motivoId", padroes, ...props });
  return <SelectMotivoDescarte {...field} {...meta} {...props} />;
}

export default FormikSelectMotivoDescarte;
