import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Button, withStyles } from "@material-ui/core";

import { Flex } from "../_common";

function SeletorAgrupamento({ agrupamento, setAgrupamento, classes }) {
  const intl = useIntl();
  return (
    <Flex>
      {[
        { texto: intl.formatMessage({ defaultMessage: "DIA" }), id: 0 },
        { texto: intl.formatMessage({ defaultMessage: "MÊS" }), id: 2 },
        { texto: intl.formatMessage({ defaultMessage: "ANO" }), id: 3 },
      ].map(({ texto, id }) => (
        <Button
          key={id}
          onClick={() => setAgrupamento(id)}
          className={id === agrupamento ? classes.black : classes.light}
        >
          {texto}
        </Button>
      ))}
    </Flex>
  );
}

SeletorAgrupamento.propTypes = {
  agrupamento: PropTypes.number,
  setAgrupamento: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles({
  dark: { color: "black" },
  light: { color: "lightgray" },
})(SeletorAgrupamento);
