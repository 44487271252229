import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { useIntl } from "react-intl";
import SelectAutoComplete from "./SelectAutoComplete";

function SelectListasPais({ value, onChange, field, form, ...props }) {
  const lista = [{ id: 1, descricao: "Brasil" }];
  const change = field ? v => form.setFieldValue(field.name, v) : v => onChange(v);
  const intl = useIntl();

  return (
    <SelectAutoComplete
      label={intl.formatMessage({ defaultMessage: "País" })}
      placeholder={intl.formatMessage({ defaultMessage: "Digite para filtrar" })}
      options={lista}
      value={field ? field.value : value}
      onChange={nome =>
        change(nome ? { id: lista.find(({ descricao }) => descricao === nome).id, descricao: nome } : {})
      }
      {...props}
      disabled={!lista.length || props.disabled}
    />
  );
}

SelectListasPais.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    descricao: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  classes: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
};

export default withStyles({ root: {} })(SelectListasPais);
