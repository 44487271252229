import React from "react";
import { useField } from "formik";

import SelectConversoes from "./SelectConversoes";

function FormikSelectConversoes(props) {
  const [field, meta] = useField({ name: "etapaId", ...props });

  return <SelectConversoes {...field} {...meta} {...props} />;
}

export default FormikSelectConversoes;
