import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";

function OrganizacaoEditFormObservacao() {
  const intl = useIntl();
  return (
    <Field
      label={intl.formatMessage({ defaultMessage: "Observação" })}
      component={TextField}
      name="observacao"
      inputProps={{ maxLength: 500 }}
      fullWidth
      multiline
      rows="4"
    />
  );
}

export default OrganizacaoEditFormObservacao;
