import React from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useIntl } from "react-intl";
import axios from "axios";

import { Loading, createSnackbar, createSnackbarAPIException } from "../../../_common";
import OrganizacaoEditForm from "../EditForm/EditForm";
import OrganizacaoInfo from "../Info/Info";
import Outline from "../../../pages/lead/components/Outline";
import { PAIS_BRASIL } from "../../../_common/constantes";
import { GET_DEFAULT_TELEFONE } from "../../../pages/lead/components/Telefones/Telefones";

const GET_DEFAULT_ENDERECO = () => ({
  pais: { ...PAIS_BRASIL },
  estado: { id: null, descricao: "" },
  cidade: { id: null, descricao: "" },
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cep: "",
});

export const GET_INITIAL_VALUES = (org = {}) => ({
  nome: "",
  site: "",
  cpfCnpj: "",
  ...org,
  telefones: !org?.telefones?.length ? [GET_DEFAULT_TELEFONE()] : org.telefones,
  endereco: org?.endereco ? { ...GET_DEFAULT_ENDERECO(), ...org.endereco } : GET_DEFAULT_ENDERECO(),
});

OrganizacaoCardEdit.propTypes = {
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

function OrganizacaoCardEdit({ orgId }) {
  const intl = useIntl();
  const { data: org, isValidating: isValidatingOrg, mutate: mutateOrg } = useSWR(
    `/api/pipeline/organizacao/Buscar?id=${orgId}`
  );

  const onSubmit = async values => {
    let success = false;
    values.id = orgId;
    values.telefones = values.telefones.filter(t => !!t.numero);

    try {
      await axios.post("/api/pipeline/organizacao/salvar", values);
      createSnackbar(intl.formatMessage({ defaultMessage: "Alterações salvas com sucesso" }));
      success = true;
      mutateOrg();
    } catch (err) {
      createSnackbarAPIException(err);
    }
    return new Promise(resolve => resolve(success));
  };

  return (
    <Outline
      card
      title={intl.formatMessage({ defaultMessage: "Empresa/Organização" })}
      form={OrganizacaoEditForm}
      initialValues={GET_INITIAL_VALUES(org)}
      onSubmit={onSubmit}
      editButtonId="org_edit-button"
      CardProps={{ style: { margin: "24px 0 12px 0" } }}
      orgId={orgId}
    >
      <Loading isLoading={isValidatingOrg}>
        <OrganizacaoInfo org={org} />
      </Loading>
    </Outline>
  );
}

export default OrganizacaoCardEdit;
